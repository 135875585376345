import './App.css';

import React, { Suspense, lazy } from 'react';
import { Routes , Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const Login = lazy(() => import('./Login'));
const Register = lazy(() => import('./SubscribeLoginRegister/Register'));
const Subscibe = lazy(() => import('./SubscribeLoginRegister/Subscribe'));
const Verify = lazy(() => import('./SubscribeLoginRegister/Verify'));
const Protected = lazy(() => import('./Protected'));
const Fixtures = lazy(() => import('./Fixtures/Fixtures'));

const Streak = lazy(() => import('./Streaks/Streak'));
const Alerts = lazy(() => import('./Alerts/Alerts'));
const Browsernoti = lazy(() => import('./Alerts/Browsernoti'));
const Listbuilder = lazy(() => import('./Listbuilder/Listbuilder'));
const Listbuilderresults = lazy(() => import('./Listbuilder/Listbuilderresults'));
const Livescore = lazy(() => import('./Fixtures/Livescore'));
const Matchdetail = lazy(() => import('./MatchDetail/Matchdetail'));
const Profile = lazy(() => import('./Profile'));


const Goalsummary = lazy(() => import('./Stats/Goalsummary'));
const Cornerssummary = lazy(() => import('./Stats/Cornersummary'));
const Cardsummary = lazy(() => import('./Stats/Cardsummary'));
const Over15goals = lazy(() => import('./Stats/Over15goals'));
const Over25goals = lazy(() => import('./Stats/Over25goals'));
const Over35goals = lazy(() => import('./Stats/Over35goals'));
const Bttsgoals = lazy(() => import('./Stats/Bttsgoals'));
const Over05fhggoals = lazy(() => import('./Stats/Over05fhggoals'));
const Over05shggoals = lazy(() => import('./Stats/Over05shggoals'));

//MATCH DETAILS const
const Teamsummarydetail = lazy(() => import('./MatchDetail/Teamsummarydetail'));
const Teamhistorydetail = lazy(() => import('./MatchDetail/Teamhistorydetail'));
const Standingsdetail = lazy(() => import('./MatchDetail/Standingsdetail'));
const Squaddetail = lazy(() => import('./MatchDetail/Squaddetail'));
const Streakdetail = lazy(() => import('./MatchDetail/Streakdetail'));
const Goaldetail = lazy(() => import('./MatchDetail/Goaldetail'));
const Cornerdetail = lazy(() => import('./MatchDetail/Cornerdetail'));
const Cardsdetail = lazy(() => import('./MatchDetail/Cardsdetail'));


function App() {
  return (
    <>
      <div>
        <ToastContainer />
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Login/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/subscribe" element={<Subscibe/>}/>
          <Route path="/verify" element={<Verify/>}/>

          <Route path="/fixtures" element={<Protected Component={Fixtures}/>}/>
          <Route path="/profile" element={<Protected Component={Profile}/>}/>

          <Route path="/goalsummary" element={<Protected Component={Goalsummary}/>}/>
          <Route path="/cornersummary" element={<Protected Component={Cornerssummary}/>}/>
          <Route path="/cardsummary" element={<Protected Component={Cardsummary}/>}/>
          <Route path="/over05fhgoals" element={<Protected Component={Over05fhggoals}/>}/>
          <Route path="/over05shgoals" element={<Protected Component={Over05shggoals}/>}/>
          <Route path="/bttsgoals" element={<Protected Component={Bttsgoals}/>}/>
          <Route path="/over15goals" element={<Protected Component={Over15goals}/>}/>
          <Route path="/over25goals" element={<Protected Component={Over25goals}/>}/>
          <Route path="/over35goals" element={<Protected Component={Over35goals}/>}/>
          
          <Route path="/streaks" element={<Protected Component={Streak}/>}/>
          <Route path="/alerts" element={<Protected Component={Alerts}/>}/>
          <Route path="/browsernoti" element={<Protected Component={Browsernoti}/>}/>
          <Route path="/listbuilder" element={<Protected Component={Listbuilder}/>}/>
          <Route path="/listbuilderresults/:listbuilderid" element={<Protected Component={Listbuilderresults}/>}/>
          <Route path="/livescore" element={<Protected Component={Livescore}/>}/>

          
          <Route path="/matchdetails/:matchid/:hometid/:awaytid" element={<Protected Component={Matchdetail}/>}/>
          <Route path="/teamsummary/:matchid/:hometid/:awaytid" element={<Protected Component={Teamsummarydetail}/>}/>
          <Route path="/teamhistory/:matchid/:hometid/:awaytid" element={<Protected Component={Teamhistorydetail}/>}/>
          <Route path="/standingdetails/:matchid/:hometid/:awaytid" element={<Protected Component={Standingsdetail}/>}/>
          <Route path="/squaddetails/:matchid/:hometid/:awaytid" element={<Protected Component={Squaddetail}/>}/>
          <Route path="/streakdetails/:matchid/:hometid/:awaytid" element={<Protected Component={Streakdetail}/>}/>
          <Route path="/goaldetails/:matchid/:hometid/:awaytid" element={<Protected Component={Goaldetail}/>}/>
          <Route path="/cornerdetails/:matchid/:hometid/:awaytid" element={<Protected Component={Cornerdetail}/>}/>
          <Route path="/cardsdetails/:matchid/:hometid/:awaytid" element={<Protected Component={Cardsdetail}/>}/>
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
